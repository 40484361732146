<template>
  <b-row class="match-height">
    <b-col sm="12">
      <b-card-actions action-collapse :title="'Akun - ' + akun.kategori ? akun.kategori.kategori : null">
        <b-row>
          <b-col cols="12">
            <h2 class="text-primary">( {{ akun.nomor }} ) {{ akun.nama }}</h2>
          </b-col>
          <b-col cols="12">
            <h3 class="text-info">Saldo : Rp. {{ akun.saldo >= 0 ? formatRupiah(akun.saldo) : "( " +
        formatRupiah(akun.saldo * -1) + " )" }}</h3>
          </b-col>
          <b-col cols="1">
            <b-badge v-if="akun.jenis == 1" variant="success">Debit</b-badge>
            <b-badge v-else-if="akun.jenis == 2" variant="danger">Kredit</b-badge>
            <b-badge v-else variant="light-danger">-</b-badge>
          </b-col>
          <b-col cols="1">
            <b-badge v-if="akun.jenis_neraca == null" variant="light-danger">-</b-badge>
            <b-badge v-else-if="akun.jenis_neraca.neraca == 'Aktiva Lancar'" variant="light-success">{{
        akun.jenis_neraca.neraca }}</b-badge>
            <b-badge v-else-if="akun.jenis_neraca.neraca == 'Aktiva Tetap'" variant="light-info">{{
        akun.jenis_neraca.neraca }}</b-badge>
            <b-badge v-else-if="akun.jenis_neraca.neraca == 'Utang Lancar'" variant="light-primary">{{
        akun.jenis_neraca.neraca }}</b-badge>
            <b-badge v-else-if="akun.jenis_neraca.neraca == 'Ekuitas'" variant="light-warning">{{
        akun.jenis_neraca.neraca }}</b-badge>
          </b-col>
        </b-row>
      </b-card-actions>
    </b-col>
    <b-col>
      <b-card>
        <b-row>
          <b-col md="4" sm="6">
            <b-button v-b-modal.modal-tambah variant="primary" @click="add()" v-if="allowCreate()">
              <feather-icon icon="PlusIcon" class="mr-50" />
              Transaksi Akun
            </b-button>
          </b-col>

          <!-- <b-col md="4" sm="4">
            
          </b-col> -->
        </b-row>
        <b-modal v-model="showModal" id="modal-" ok-title="Tutup" ok-variant="secondary" size="lg" ok-only centered
          title="Form ">
          <validation-observer ref="formakun">
            <b-form>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Pilih Kas">
                    <!-- label-for="v-id_kas" -->
                    <validation-provider #default="{ errors }" rules="required" name="id_kas">
                      <b-form-select v-model="form.id_kas" :options="id_kas" id="v-id_kas" name="id_kas" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Tanggal & Waktu" label-for="v-tanggal">
                    <validation-provider #default="{ errors }" name="tanggal" :rules="{ required: true }">
                      <flat-pickr v-model="form.tanggal" class="form-control"
                        :config="{ enableTime: false, dateFormat: 'Y-m-d' }" />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Debit -->
                <b-col cols="6" v-if="akun.jenis == 1">
                  <b-form-group label="Nominal" label-for="v-debit">
                    <validation-provider #default="{ errors }" rules="required" name="debit">
                      <b-input-group prepend="Rp." class="input-group-merge">
                        <b-form-input :state="errors.length > 0 ? false : null" v-model="form.debit" id="v-debit"
                          placeholder="Isi Nominal" @keyup="form.debit = formatRupiah(form.debit)" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-input-group>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Kredit -->
                <b-col cols="6" v-if="akun.jenis == 2">
                  <b-form-group label="Nominal" label-for="v-kredit">
                    <validation-provider #default="{ errors }" rules="required" name="kredit">
                      <b-input-group prepend="Rp." class="input-group-merge">
                        <b-form-input :state="errors.length > 0 ? false : null" v-model="form.kredit" id="v-kredit"
                          placeholder="Isi Nominal" @keyup="form.kredit = formatRupiah(form.kredit)" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-input-group>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Keterangan -->
                <b-col>
                  <b-form-group label="Keterangan" label-for="v-keterangan">
                    <validation-provider #default="{ errors }" rules="required" name="kterangan">
                      <b-form-input v-model="form.keterangan" id="v-keterangan" placeholder="Isi keterangan" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>


                <!-- submit and reset -->
                <b-col cols="12">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                    @click.prevent="submit">
                    Tambah
                  </b-button>
                  <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                    Reset
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
        <b-row>
          <b-col xl="2" lg="2" md="2" sm="2" class="mb-1">
            <b-form-group class="mb-0" label="Per Page" label-for="perPageSelect">
              <b-form-select id="perPageSelect" size="sm" v-model="perPage" :options="pageOptions" />
            </b-form-group>
          </b-col>
          <b-col md="4" sm="8" class="" v-if="this.user.member_id = null">
            <b-form-group label="Pilih Affiliator" label-for="is_member">
              <b-input-group>
                <b-form-select size="sm" id="is_member" v-model="is_member" :options="optis_member" class="" @input="getjurnal()">
                  <template #first>
                    <b-form-select-option :value="null">-- Semua --</b-form-select-option>
                  </template>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="6" md="8" sm="8" class="" >
            <b-form-group label="Filter" label-for="filterInput" class="mb-0">
              <b-input-group>
                <b-form-input id="filterInput" size="sm" v-model="filter" type="search" placeholder="Tekan enter untuk cari" @keydown.enter.prevent="getTransaksi()" />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    size="sm"
                    @click="
                      filter = '';
                      getjurnal();
                    "
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
                <template v-if="items.length > 0">
          <b-col cols="12">
            <b-table v-if="akun.jenis == 1 || akun.jenis == 2" striped small hover responsive :per-page="perPage"
              :current-page="currentPage" :items="items" :fields="fields" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
              :filter-included-fields="filterOn" @filtered="onFiltered">
              <template #cell(no)="data">
                {{ data.index + 1 }}
              </template>
          <template #cell(member_id)="data">
            {{ getmembername(data.item) }}
          </template>
              <template #cell(debit)="{ item }">
                <strong>Rp
                  {{
        item.debit > 0 ? formatRupiah(item.debit) : item.debit
      }}</strong>
              </template>
              <template #cell(kredit)="{ item }">
                <strong>Rp
                  {{
        item.kredit > 0 ? formatRupiah(item.kredit) : item.kredit
      }}</strong>
              </template>
              <!-- <template #cell(nominal)="{ item }">
                <b-badge variant="success" v-if="item.debit > 0 && item.kredit <= 0">{{ formatRupiah(item.debit + item.kredit) }}</b-badge>
                <b-badge variant="danger" v-if="item.debit <= 0 && item.kredit > 0">{{ formatRupiah(item.debit + item.kredit) }}</b-badge>
              </template> -->
              <template #cell(tanggal)="{ item }">
                {{ humanDate(item.tanggal) }}
              </template>
              <!-- <template #cell(nama)="{ item }">
                <span @click.prevent="$router.push(`/akun/${item.id}`)"><u>{{ item.nama }}</u></span>
              </template>
              <template #cell(nomor)="{ item }">
                <span @click.prevent="$router.push(`/akun/${item.id}`)"><u>{{ item.nomor }}</u></span>
              </template> -->

              <!-- <template #cell(jenis)="data">
                <b-badge :variant="jns[1][data.value]">
                  {{ jns[0][data.value] }}
                </b-badge>
              </template> -->
              <!-- <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template> -->

              <template #cell(actions)="row">
                <b-button v-if="row.item.modul == 'jurnal'" v-b-tooltip.hover.right="'Ubah'" size="sm"
                  @click="edit(row.item)" class="mr-1" variant="outline-info">
                  <feather-icon icon="EditIcon" />
                </b-button>
                <!-- v-if="allowDelete()" -->
                <b-button v-if="row.item.modul == 'jurnal'" v-b-tooltip.hover.right="'Hapus'" size="sm"
                  @click="remove(row.item)" class="mr-1" variant="outline-danger">
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>

              <template #row-details="row">
                <b-card>
                  <ul>
                    <li v-for="(value, key) in row.item" :key="key">
                      {{ key }}: {{ value }}
                    </li>
                  </ul>
                </b-card>
              </template>
            </b-table>

            <b-table v-if="akun.jenis == 3" striped small hover responsive :per-page="perPage"
              :current-page="currentPage" :items="items" :fields="debitfields" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
              :filter-included-fields="filterOn" @filtered="onFiltered">
              <template #cell(no)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(debit)="{ item }">
                <strong>Rp
                  {{
        item.debit > 0 ? formatRupiah(item.debit) : item.debit
      }}</strong>
              </template>
              <template #cell(kredit)="{ item }">
                <strong>Rp
                  {{
        item.kredit > 0 ? formatRupiah(item.kredit) : item.kredit
      }}</strong>
              </template>
              <template #cell(tanggal)="{ item }">
                {{ humanDate(item.tanggal) }}
              </template>
              <!-- <template #cell(nama)="{ item }">
                <span @click.prevent="$router.push(`/akun/${item.id}`)"><u>{{ item.nama }}</u></span>
              </template>
              <template #cell(nomor)="{ item }">
                <span @click.prevent="$router.push(`/akun/${item.id}`)"><u>{{ item.nomor }}</u></span>
              </template> -->

              <!-- <template #cell(jenis)="data">
                <b-badge :variant="jns[1][data.value]">
                  {{ jns[0][data.value] }}
                </b-badge>
              </template> -->
              <!-- <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template> -->

              <template #cell(actions)="row">
                <b-button v-if="row.item.modul == 'jurnal'" v-b-tooltip.hover.right="'Ubah'" size="sm"
                  @click="edit(row.item)" class="mr-1" variant="outline-info">
                  <feather-icon icon="EditIcon" />
                </b-button>
                <!-- v-if="allowDelete()" -->
                <b-button v-if="row.item.modul == 'jurnal'" v-b-tooltip.hover.right="'Hapus'" size="sm"
                  @click="remove(row.item)" class="mr-1" variant="outline-danger">
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>

              <template #row-details="row">
                <b-card>
                  <ul>
                    <li v-for="(value, key) in row.item" :key="key">
                      {{ key }}: {{ value }}
                    </li>
                  </ul>
                </b-card>
              </template>
            </b-table>
          </b-col>
          </template>
          <!-- <b-modal :id="detailmodal.id" :title="detailmodal.title" ok-only>
            <pre>{{ detailmodal.content }}</pre>
          </b-modal> -->

          <b-col cols="12" v-if="items.length > 0">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
              class="my-0" />
          </b-col>
          <template v-if="items.length == 0 || items.length == null ">
            <b-col cols="12">
            <b-card
                border-variant="danger"
                class="text-center"
              >
                <div class="text-danger">
                  Data belum ada
                </div>
              </b-card>
            </b-col>
            </template>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BTable,
  BCard,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormRadio,
  BButton,
  BFormTextarea,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BForm,
    BModal,
    BTable,
    BCard,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BFormRadio,
    BCardText,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      showModal: false,
      required,
      password,
      email,
      confirmed,
      form: {
        id_akun: null,
        id_kas: null,
        tanggal: this.getCurrentDate(),
        modul: 'jurnal',
        debit: 0,
        kredit: 0,
        keterangan: null,
        // saldo: null,
      },
      jenis: [
        { value: 1, text: "Masuk" },
        { value: 2, text: "Keluar" },
      ],
      id_kas: [],
      is_member: null,
      member_id: null,
      userData: JSON.parse(localStorage.getItem("userData")),
      perPage: 100,
      pageOptions: [20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "no", label: "no" },
        // { key: "id", label: "Id"},
        { key: "tanggal", label: "Tanggal", sortable: true },
        { key: "kas.nama", label: "Kas", sortable: true },
        { key: "keterangan", label: "Keterangan", sortable: true },
        //   { key: "kategori.kategori", label: "kategori", sortable: true },
        { key: "debit", label: "Masuk", sortable: true },
        { key: "kredit", label: "Keluar", sortable: true },
        { key: "member_id", label: "affiliator", sortable: true },

        // { key: "nominal", label: "nominal", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      debitfields: [
        { key: "no", label: "no" },
        // { key: "id", label: "Id"},
        { key: "tanggal", label: "Tanggal", sortable: true },
        { key: "kas.nama", label: "Kas", sortable: true },
        { key: "keterangan", label: "Keterangan", sortable: true },
        //   { key: "kategori.kategori", label: "kategori", sortable: true },
        { key: "debit", label: "Nominal", sortable: true },
        { key: "member.nama_lengkap", label: "affiliator", sortable: true },
        // { key: "kredit", label: "Kredit / Keluar", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      optis_member: null,
      akun: {},
      items: [],
      status: [
        {
          1: "Belum",
          2: "Approve SPV",
          3: "Approve Gudang",
          4: "Sudah Ambil",
        },
        {
          1: "light-danger",
          2: "light-info",
          3: "light-warning",
          4: "light-success",
        },
      ],
      jns: [
        {
          1: "Masuk",
          2: "Keluar",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    perPage(value) {
      if (value) {
        this.getjurnal();
      }
    },
    // filter(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getjurnal();
    //   }
    // },
    currentPage(val) {
      if (val) {
        this.getjurnal();
        // this.items
      }
    },
  },
  mounted() {
    if (this.$route.query.from) {
      this.$route.meta.breadcrumb[0].to = this.$route.query.from
    }
    this.getData();
    this.getjurnal();
    this.getKas();
    this.getMember()
  },
  methods: {
    getmembername(data) {
      let find = this.optis_member.find((item) => item.value == data.member_id);
      return find ? find.text : "-";
    },
    getKas() {
      this.$store
        .dispatch("kas/getData", {})
        .then(() => {
          let hem = JSON.parse(
            JSON.stringify(this.$store.state.kas.datas)
          );
          hem.map((item) => {
            item.value = item.id;
            item.text = item.nama + ' -> Rp ' + this.formatRupiah(item.saldo);
          });
          this.id_kas = hem;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    edit(item) {
      let { akun, kas } = item;
      this.form = item;
      this.id = item.id;
      if (akun) {
        this.form.id_akun = akun.id;
      }
      if (kas) {
        this.form.id_kas = kas.id;
      }
      this.showModal = true
    },
    add() {
      this.resetForm();
      // this.getKas();
      this.form.tanggal = this.getCurrentDate(),
        this.form.id_akun = this.$route.params.id;
      this.showModal = true;
      // const userData = JSON.parse(localStorage.getItem("userData"));
      // if (userData.id) {
      //   this.form.id_kategori = userData.karyawan.id;
      //   this.form.id_kas = this.$route.params.id;
      // }
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Transaksi "${item.keterangan}" ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("jurnal/save", [item])
            .then(() => {
              this.getjurnal();
              this.getData();
              // this.pesanBerhasilHapus();
              this.displaySuccess({
                message: "Data jurnal berhasil terhapus"
              });
              this.resetForm();
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          // this.getjurnal();
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        tanggal: this.getCurrentDate(),
        modul: 'kas',
        debit: 0,
        kredit: 0,
        keterangan: null,
      };
      this.showModal = false;
    },
    submit() {
      this.$refs.formakun.validate().then((success) => {
        if (success) {
          this.label = "Loading...";
          if (this.id) {
            this.form.id = this.id;
          }
          this.form.debit = this.unFormatRupiah(this.form.debit)
          this.form.kredit = this.unFormatRupiah(this.form.kredit)
          this.form.modul = 'jurnal'
          const payload = this.form;
          this.$store
            .dispatch("jurnal/save", [payload])
            .then(() => {
              this.label = "Submit";
              this.resetForm();
              this.displaySuccess({
                message: "Data Jurnal berhasil disimpan"
              });
              this.showModal = false;
              this.getjurnal();
            })
            .catch((e) => {
              this.label = "Submit";
              this.displayError(e);
              return false;
            });
        }
      });
    },
    getMember() {
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        // order: "desc",
        // start: currentPage,
        // length: this.perPage,
        // filter_by: "nama_lengkap",
        // category_paket_id: this.filterKatPaket != null ? this.filterKatPaket.id : null,
        // paket_id: this.filterPaket != null ? this.filterPaket.id : null,
        // jadwal_id: this.filterJadwal != null ? this.filterJadwal.id : null,
        // user_id: this.user.id,
      };
      this.$store.dispatch("member/getData", payload).then(() => {
        let items = JSON.parse(JSON.stringify(this.$store.state.member.datas));
        items.map((item) => {
            item.value = item.id;
            item.text = item.nama_lengkap;
          });
        this.optis_member = items;
        // this.items = this.$store.state.member.datas;
        // this.totalRows = this.items.length;
      });
    },
    async getData() {
      //SEMUA kecuali SALES
      const akun = await this.$store
        .dispatch("akun/getDataById", this.$route.params.id)
      this.akun = akun;
      // if (this.akun.jenis_neraca == null){
      //   this.akun.jenis_neraca.neraca == "-"
      // };        
    },
    getjurnal() {
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const params = {
        id_akun: this.$route.params.id,
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
      }
      if (this.user.member_id == null){
        params.member_id = this.is_member ? this.is_member : null
      } else {
        params.member_id = this.user.member_id ? this.user.member_id : null
      }
      this.$store
        .dispatch("jurnal/getData", params)
        .then(() => {
          let items = JSON.parse(JSON.stringify(this.$store.state.jurnal.datas));
          let items_total = this.$store.state.jurnal.totals;
          this.items = items
          this.totalRows = items_total;
          // let hem = JSON.parse(
          //   JSON.stringify(this.$store.state.akun_kategori.datas)
          // );
          // hem.map((item) => {
          //   item.value = item.id;
          //   item.text = item.kategori;
          // });
          // this.id_kategori = hem;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
  },
};
</script>
